
var TIMER = 5;
var timeout;
var hover = true;
( function( $ ) {
	$( document ).ready( function () {
		//$.cookie("expired", null, { path: '/' });
	} );
} ) ( jQuery );

function takeover ( smallimage, bigimgurl ) {
	//console.log(smallimage+ " "+ bigimgurl);
	( function( $ ) {
		//$( [ bigimgurl ] ).preload();
		var ads_list = [];
		$( '.ads_items a' ).each( function ( i ) {
			$( this ).mouseover( function () {
				var $href = $( this ).attr( 'href' );
				var $img = $( 'img', this );
				var $src = $img.attr( 'src' );
				var name =  get_name_from_url ( $src );
				if (ads_list[name]) {
					return false;
				} else {
					hover = true;
				}
				
				if ( name == smallimage ) {
					if(hover){
						$.fancybox.open(
							[
								{
										href : bigimgurl,
										title : 'This ads will be closed in <span id="timer">' + TIMER + '</span> seconds.'
									},
							],
							{
								afterShow: function () {
									//console.log( 'Lightbox showed!' );
									$( '.fancybox-image' ).css( 'cursor', 'pointer' );
									$( '.fancybox-image' ).click( function () {
										//window.location.href = $href;
										window.open(
											$href,
											'_blank' // <- This is what makes it open in a new window.
										);
										$.fancybox.close( true );
									} );
									timeout = setInterval( 'timeout_trigger()', 1000);
								},
								afterClose: function () {
									clearInterval( timeout );
									// console.log( 'Ads is closed' );
								}
							}
						);
						ads_list[name] = name;
						hover = false;
					}
				}
				// console.log( $href );
				// console.log( $src );
				// console.log( get_name_from_url ( $src ) );
			} );
		} );
	} ) ( jQuery );
}

function show_large ( smallimage, bigimgurl ) {
	( function( $ ) {
	$( document ).ready( function () {
		$( [ bigimgurl ] ).preload();
		$( '.ads a' ).each( function ( i ) {
			var $href = $( this ).attr( 'href' );
			var $img = $( 'img', this );
			var $src = $img.attr( 'src' );
			var name =  get_name_from_url ( $src );
			if ( name == smallimage ) {
				var expired =  getCookie( 'expired' );
				if ( expired == '' || ( expired != '' && expired < get_tomorrow() ) ) { 
				$.fancybox.open(
					[
						{
					        	href : bigimgurl,
					        	title : 'This ads will be closed in <span id="timer">' + ( TIMER ) + '</span> seconds.'
					    	},
					],
					{
						afterShow: function () {
							$.fancybox.helpers.overlay.open();
							$( '.fancybox-image' ).css( 'cursor', 'pointer' );
							$( '.fancybox-image' ).click( function () {
								window.open(
									$href,
									'_blank' // <- This is what makes it open in a new window.
								);
								$.fancybox.close( true );
								$( '.fancybox-opened' ).hide();
					                        $( '.fancybox-overlay.fancybox-overlay-fixed' ).hide();
					                        $( 'html' ).removeClass( 'fancybox-margin' );
					                        $( 'html' ).removeClass( 'fancybox-lock' );
					                        clearInterval( timeout );
							} );
							timeout = setInterval( 'timeout_trigger()', 1000);
						},
						afterClose: function () {
							clearInterval( timeout );
							// console.log( 'Ads is closed' );
						}
					}
				);
				setCookie( 'expired', get_tomorrow() );
				//$( 'html' ).addClass( 'fancybox-margin' );
				//$( 'html' ).addClass( 'fancybox-lock' );
				}
				// console.log( 'Load large' );
				$( this ).hide();
				//return false;
			}
		} );
	} );
	} ) ( jQuery );
}

function get_name_from_url ( url ) {
	var name = '';
	( function( $ ) {
		var args = url.split( '/' );
		var filename = args[ args.length -1 ];
		name = filename.split( '.' )[0];
		//console.log( name );
		//console.log( bigimgurl )
	} ) ( jQuery );
	return name;
}

( function( $ ) {
$.fn.preload = function () {
    	this.each( function ( i, value ){
    		//console.log( value );
        	//$( 'body' ).append( $( '<img src="' + value + '" style="display: none;"/>' ) );
    	});
}
} ) ( jQuery );

function timeout_trigger () {
	( function( $ ) {
		timer = parseInt( $( '#timer' ).text() );
		$( '#timer' ).text( timer - 1 );
		if ( timer == 0 ) {
			//$.fancybox.close( true );
			$( '.fancybox-opened' ).hide();
			$( '.fancybox-overlay.fancybox-overlay-fixed' ).hide();
			$( 'html' ).removeClass( 'fancybox-margin' );
			$( 'html' ).removeClass( 'fancybox-lock' );
			clearInterval( timeout );
		}
	} ) ( jQuery );	
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

function get_date ( pdate  ) {
	var currentDate = new Date( pdate );
	var day = currentDate.getDate();
	var month = currentDate.getMonth() + 1;
	var year = currentDate.getFullYear();
	var fdate =  year + '' + month + '' + day;
	return parseInt( fdate );
}

function get_tomorrow () {
	return get_date( new Date().getTime() + 24 * 60 * 60 * 1000 );
}

function get_today () {
	return get_date( new Date().getTime() );
}


function sb_ads_startup(link, image) {

	// console.log(image);
	// console.log(link);
	setCookie("sb_ads_startup", 1, get_tomorrow ());

	$.fancybox.open(
		[
			{
		        	href : image,
		        	title : 'This ads will be closed in <span id="timer">' + ( TIMER ) + '</span> seconds.'
		    	},
		],
		{
			afterShow: function () {
				$.fancybox.helpers.overlay.open();
				$( '.fancybox-image' ).css( 'cursor', 'pointer' );
				$( '.fancybox-image' ).click( function () {
					window.open(
						link,
						'_blank' // <- This is what makes it open in a new window.
					);
					$.fancybox.close( true );
					$( '.fancybox-opened' ).hide();
		                        $( '.fancybox-overlay.fancybox-overlay-fixed' ).hide();
		                        $( 'html' ).removeClass( 'fancybox-margin' );
		                        $( 'html' ).removeClass( 'fancybox-lock' );
		                        clearInterval( timeout );
				} );
				timeout = setInterval( 'timeout_trigger()', 1000);
			},
			afterClose: function () {
				clearInterval( timeout );

				// console.log( 'Ads is closed' );
			}
		}
	);

}



