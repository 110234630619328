var space = false;
var waypoint;

function piwikTrackGoal(int){
  try {
    var piwikTracker = Piwik.getAsyncTracker();
    piwikTracker.trackGoal(int);
    // console.log('trackGoal('+int+')');
  } catch( err ) {}
}

function zero_space(first) {
  var checked = $('.zero_space_switch').is(':checked');
  if (checked) {
    with_space();
  } else if(!first) {
    no_space();
  }
} 

$('.onoffswitch-wrapper').click(function() {
    piwikTrackGoal(7);
    zero_space(false);
});


function no_space() {
  $('.post_content .detail').find('p').each(function(i, p) {    
    var img = $(this).find("img").length;
    var iframe = $(this).find("iframe").length;
    if (img == 0 && iframe == 0) {
      ori = p.textContent;
      p.textContent = ori.replace(/ /g, '​');
    }
  });
}

function with_space() {
  $('.post_content .detail').find('p').each(function(i, p) {
    var img = $(this).find("img").length;
    var iframe = $(this).find("iframe").length;
    if (img == 0 && iframe == 0) {
      ori = p.textContent;
      p.textContent = ori.replace(/​/g, ' ');
    }
  });
}

//tracking code and event of sabay news
$(document).ready( function(){

  // detect mobile
  var is_mobile = ($('body').width() <= 480) ? true : false;
  var fb_campaign = "#utm_campaign=fb";
  var fb_top = $('#fb_share_top');
  var rel_top = fb_top.attr('rel')+fb_campaign;
  var fb_bot = $('#fb_share_bottom');
  var rel_bot = fb_bot.attr('rel')+fb_campaign;
  var url_top = "";
  var url_bot = "";
  if (is_mobile) {
      url_top = rel_top+"&utm_term=m.facebook.com";
      fb_top.attr('rel', url_top);
      url_bot = rel_bot+"&utm_term=m.facebook.com";
      fb_bot.attr('rel', url_bot);
  } else {
      url_top = rel_top+"&utm_term=www.facebook.com";
      fb_top.attr('rel', url_top);
      url_bot = rel_bot+"&utm_term=www.facebook.com";
      fb_bot.attr('rel', url_bot);
  }

  //click on share button on top
  $('#fb_share_top').click(function(e){
    e.preventDefault();
    var url = $(this).attr('rel');  
    
    FB.ui({
      method: 'feed',
     // picture: image,
      link: url,
     // caption: cap,
    }, function(response) {

      if (response && !response.error_code) {
            console.log("OK: "+JSON.stringify(response));
            piwikTrackGoal(1);
        } else if (response && response.error_code === 4201) { //Cancelled
            console.log("User cancelled: "+decodeURIComponent(response.error_message));
        } else {
            console.log("Not OK: "+JSON.stringify(response));
        }
    });
      return false;
  });

  //click on page like on sidebar
  $('#fb_page_like').click(function(){
    // piwikTracker.trackGoal(2);
    piwikTrackGoal(2);
  });

  //click on share button on bottom of article page
  $('#fb_share_bottom').click(function(e){
    e.preventDefault();
    var url = $(this).attr('rel');  
    
    FB.ui({
      method: 'feed',
     // picture: image,
      link: url,
     // caption: cap,
    }, function(response){

      if (response && !response.error_code) {
            console.log("OK: "+JSON.stringify(response));
            // piwikTracker.trackGoal(3);
            piwikTrackGoal(3);
        } else if (response && response.error_code === 4201) { //Cancelled
            console.log("User cancelled: "+decodeURIComponent(response.error_message));
        } else {
            console.log("Not OK: "+JSON.stringify(response));
        }
    });
      return false;
  });

  //click on recommend article on sidebar
  $('.right-hot-news .media').click(function(){
    // piwikTracker.trackGoal(4);
    piwikTrackGoal(4);
  });

  //click on the tag below the article
  $('.post_tags a.tag').click(function(){
    // piwikTracker.trackGoal(6);
    piwikTrackGoal(6);
  });

  //click on each menu
  $('ul.navbar-nav li').click(function(){
    var home = $(this).hasClass('home');
    if(!home){
      //if it is menu click
      // piwikTracker.trackGoal(14);
      piwikTrackGoal(14);
    }else{
      //if it is home click
      // piwikTracker.trackGoal(13);
      piwikTrackGoal(13);
    }     
  });


  //click on button word space
  zero_space(true);

});





