var ad_zone_01 = false;
jQuery.fn.extend({
  loading: function () {
    $(this).append('<div class="loading-content"><i class="fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom"></i></div>');
    return;
  }
});

window.view_type = "list";

var symbolMap = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '0': '0'
}, numberMap = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '0': '0'
};

moment.updateLocale('km', {
  preparse: function (string) {
      return string.replace(/[1234567890]/g, function (match) {
          return numberMap[match];
      });
  },
  postformat: function (string) {
      return string.replace(/\d/g, function (match) {
          return symbolMap[match];
      });
  }
});

function update_moment() {

  var options = {
    lastWeek: 'LLLL',
    sameElse: 'LLLL'
  }

  $.each($('.pub-date'),function() {
    var date = $(this).attr('date');
    localtime = moment.utc(date).toDate();
    $(this).text(moment(localtime).calendar(null, options));
  });

}

$(document).ready(function () {
  var mobile = ($(window).width() <= 768) ? true : false;
  if (mobile) {
    $('section#footer').remove();
  }
  update_moment();

  $('.tab-by-category .nav-tabs li:not(.title, .sponsor) a:not(.switch-view)').on('click', function () {
    $(this).parents('.tab-by-category').find('.nav-tabs li').removeClass('active');
    $(this).parent().addClass('active');
    var url = $(this).attr('href');
    var to = $(this).attr('for');
    var except_ids = $(this).parents('.tab-by-category').attr('rel');
    load_tab_categories(url, to, except_ids);
    return false;
  });

  $('a.switch-view').on('click', function () {
    var type = $(this).attr('id');
    window.view_type = type;
    $('a.switch-view').parent().removeClass('active');
    $(this).parent().addClass('active');
    update_post_view_type();
    return false;
  });

  load_first_tab_category();
  update_post_view_type();
  fixed_menu();

  if ($(window).width() <= 767) {
    if (landing_page == 'home') {
      load_more_posts_homepage();
    }
    has_campaign();
    $('.ad-cover-mr2').css('height', ($(window).height()+60));
  }
  if (landing_page == 'category' || landing_page == 'tag') {
    load_more_posts();
  }
  $(window).scroll(function () {
    fixed_menu();
    load_first_tab_category();

    if ($(window).width() <= 767) {
      if (landing_page == 'home') {
        load_more_posts_homepage();
      }
    }
    if (landing_page == 'category' || landing_page == 'tag') {
      load_more_posts();
    }
  });

  $(window).resize(function () {
    update_post_view_type();
    fixed_menu();
    load_first_tab_category();
    display_content_slideshow();
  });

  $(".carousel.slide").carousel({
    swipe: 30 // percent-per-second, default is 50. Pass false to disable swipe
  });

  $('#features_post .item a').hover(function () {
    $(this).find('.content').slideDown({ queue: false });

    $(this).find('.bg').animate({
      opacity: '1'
    }, { queue: false, duration: 300 });
    $(this).find('.title').css({
      color: '#4873AF'
    });
  }, function () {
    $(this).find('.content').slideUp({ queue: false });

    $(this).find('.bg').animate({
      opacity: '0.5'
    }, { queue: false, duration: 300 });
    $(this).find('.title').css({
      color: '#ffffff'
    });
  });

  $('#menu #search input').focus(function () {
    if (!window.is_search_show) {
      show_search();
    }
  });

  $('body').click(function (evt) {
    if ($(evt.target).closest('#menu #search').length) {
      return;
    }
    if (window.is_search_show) {
      hide_search();
    }
  });

  $('#menu #search a').click(function () {
    if (!window.is_search_show) {
      show_search();
    } else {
      hide_search();
    }
    return false;
  });

  $('#menu #search form').submit(function () {
    if (!window.is_search_show) {
      show_search();
      $('#menu #search input').focus();
      return false;
    }
  });
  display_content_slideshow();

  var total = $("#kanha_slider").attr("total") < 3 ? $("#kanha_slider").attr("total") : 3;
  $("#kanha_slider").flexisel({
    visibleItems: total,
    itemsToScroll: 1,
    autoPlay: {
      enable: false,
      interval: 5000,
      pauseOnHover: true
    }
  });

  //update color of read article
  update_gray_on_read_article();
});

function update_gray_on_read_article() {
  var item = $('#posts_list .item').length;
  if (item > 0) {
    $.each($('#posts_list .item'), function (index, value) {
      var article_id = $(this).attr('post_id');
      if (article_id !== undefined || article_id !== null) {
        var article_ids = localStorage.getItem('article_ids');
        if (article_ids != null) {
          var array_article = article_ids.split(',');
          if (array_article.indexOf(article_id) > -1) {
            $(this).addClass('read-article');
          }
        }
      }
    });
  }
}
function display_content_slideshow() {
  if ($("#content_slider").length) {
    var items = $("#content_slider").find('.item');
    var a = false;
    items.each(function (index, element) {
      if (!$(this).hasClass("active")) {
        $(this).addClass("active");
        a = true;
      }
      var caption_h = 0;
      if ($(this).find('.carousel-caption').length) {
        caption_h = $(this).find('.carousel-caption').height() + 20;
      }
      height = ($("#content_slider").height() - caption_h);
      $(this).find('.img').height(height);
      if (a) {
        $(this).removeClass("active");
        a = false;
      }
    });
  }
}

window.is_search_show = false;
function show_search() {
  window.is_search_show = true;
  var width = $(document).innerWidth();
  if (width <= 767) {
    $('#menu #search input').focus();
    $('#menu #search form button.left_submit').fadeIn();
    $('#menu #search input').css({ 'padding': '0 15px 0 38px' });
    $('#menu #search a').html('<i class="fa fa-times"></i>');
    $('#menu #search input').animate({
      width: 275
    }, { queue: false, duration: 300 });
  } else {
    $('#menu #search input').css({ 'padding': '0 30px 0 15px' });
    $('#menu #search input').animate({
      width: 300
    }, { queue: false, duration: 300 });
  }

}

function hide_search() {
  window.is_search_show = false;
  var width = $(document).innerWidth();
  var toW = 0;
  if (width <= 767) {
    $('#menu #search form button.left_submit').hide();
    $('#menu #search a').html('<i class="fa fa-search"></i>');
    $('#menu #search form button').css({ 'color': '#ffffff' });
  }
  $('#menu #search input').css({ 'padding': '0' });
  $('#menu #search input').animate({
    width: toW
  }, { queue: false, duration: 300 });
}

$(document).on("click", ".tab-by-category .btn_next_prev[rel=ajax]", function (e) {
  var url = $(this).attr('href');
  var to = $(this).parents('.tab-by-category').find('.ajax-content').attr('id');
  var except_ids = $(this).parents('.tab-by-category').attr('rel');
  load_tab_categories(url, to, except_ids);
  return false;
});


window.loaded_categies_arr = [];
function load_first_tab_category() {
  var elements = $('div.tab-by-category');
  if (typeof elements.offset() != "undefined") {
    var scroll = $(this).scrollTop() + $(window).innerHeight() + 150;
    elements.each(function () {
      var tab_id = $(this).attr('id');
      if ((window.loaded_categies_arr.indexOf(tab_id) == -1) && scroll >= $(this).offset().top) {
        window.loaded_categies_arr.push(tab_id);
        var tab = $(this).find('.nav-tabs li.active a[rel=ajax]');
        if (typeof tab.offset() != "undefined") {
          var url = tab.attr('href');
          var to = tab.attr('for');
          var except_ids = $(this).attr('rel');
          load_tab_categories(url, to, except_ids);
        }
      }
    });
  }
}

function fixed_menu() {
  var before_menu = $('div#before_menu');
  var menu = $('nav#menu');
  var width = $(document).innerWidth();
  if (width <= 767) {
    menu.removeClass('navbar-static-top').addClass('navbar-fixed-top');
    $('.mean-content').css({ 'margin-top': '88px' });
    return;
  }

  if (typeof before_menu.offset() != "undefined") {
    if (($(this).scrollTop() >= before_menu.offset().top)) {
      menu.removeClass('navbar-static-top').addClass('navbar-fixed-top');
      $('.mean-content').css({ 'margin-top': (menu.height() + 15) + 'px' });
    } else {
      menu.removeClass('navbar-fixed-top').addClass('navbar-static-top');
      $('.mean-content').css({ 'margin-top': '15px' });
      ($('.mean-content').hasClass('special')) ? $('.mean-content').css({ 'margin-top': '0' }) : '';
    }
  }
}

function update_post_view_type() {
  var type = window.view_type;
  var width = $(document).innerWidth();
  if (width <= 767 || type == "grid") {
    $('#posts_list').removeClass('list').addClass('grid');
    $('#posts_list .list-items').addClass('row');
    $('#posts_list .list-items .list-item').removeClass('row').addClass('col-md-4').addClass('col-xs-12');
    $('#posts_list .list-items .list-item .tumbnail').removeClass('col-md-4');
    $('#posts_list .list-items .list-item .content').removeClass('col-md-8');
  } else {
    $('#posts_list').removeClass('grid').addClass('list');
    $('#posts_list .list-items').removeClass('row');
    $('#posts_list .list-items .list-item').addClass('row').removeClass('col-md-4').removeClass('col-xs-12');
    $('#posts_list .list-items .list-item .tumbnail').addClass('col-md-4');
    $('#posts_list .list-items .list-item .content').addClass('col-md-8');
  }
}

function load_tab_categories(url, to, except_ids) {
  $(".ajax-content#" + to).loading();
  $.ajax({
    url: url,
    success: function (result) {
      $(".ajax-content#" + to).html(result);
    }
  });
}

function do_ajax_action(url, callback) {
  $.ajax({
    url: url,
    success: function (result) {
      callback(result);
    }
  });
}

window.is_loading = false;
window.current_page = 1;

function load_more_posts() {
  var default_ads = this.options.default_ads;
  var type = $('#type').val();
  var windowHeight = $(window).height();

  var parent = $('#posts_list_mobile');
  var bottomOfParent = parent.offset().top + parent.outerHeight(true);
  // start load content when scroll to top lasted posts element + 2 screen height
  var startLoadTop = ((bottomOfParent - windowHeight) - (windowHeight * 2));
  var ad_cover_height = 270;
  if ($(window).width() <= 767) {
    var limit = 10;
    var loading = $('#display_more_posts');
  } else {
    var limit = 14;
    var loading = $('#loading_more_post');
  }

  if (typeof loading.offset() != "undefined") {
    if ($(this).scrollTop() >= startLoadTop && !window.is_loading) {
      display_ads_config();
      //      piwikTracker.trackGoal(10);
      window.is_loading = true;
      window.current_page += 1;
      var page = window.current_page + 1;
      var rel = loading.attr('rel');
      var url = DOMAIN + rel + "/" + window.current_page + "/" + limit;
      loading.find('i').show();
      $.ajax({
        url: url,
        data: {'type' : type},
        success: function (result) {
          loading.find('i').hide();
          var category_bottom_ads_desktop = "";
          var ads = '';
          var width = 728;
          var height = 90;
          $('.category_bottom_ads').find('.ads_items').each(function (e) {
            var zone = $(this);
            var zone_id = zone.attr('zone');
            if (typeof zone_id !== "undefined") {
              var random_number = Math.floor(Math.random() * 99999999999);
              var mobile = ($(window).width() <= 768) ? true : false;
              if (mobile) {
                if(is_load_mr1) {
                  zone_id = default_ads[1].zone;
                  width  = 300;
                  height = 270;
                  ad_cover_height = 270;
                  var height_iframe = 'margin-top: 50%';
                  var width_iframe = 'auto';
                } else if(is_load_mr2) {
                  zone_id = default_ads[4].zone;
                  width  = 320;
                  height = $(window).height()+60;
                  ad_cover_height = windowHeight + 60;
                  var height_iframe = 'margin-top: 0';
                  var width_iframe = '100%';
                } else {
                  width  = 300;
                  height = 270;
                  var height_iframe = 'margin-top: 50%';
                  var width_iframe = 'auto';
                }
              }
              ads = "<div class='ads_items'><iframe id='a5dd2a7c' name='a5dd2a7c' src='https://ads.sabay.com/openx/www/delivery/afr.php?zoneid=" + zone_id + "&amp;cb=" + random_number + "' frameborder='0' scrolling='no' width='" + width + "' height='" + height + "'><a href='https://ads.sabay.com/openx/www/delivery/ck.php?n=ae6639d1&amp;cb=" + random_number + "' target='_blank'><img class='img-responsive' src='https://ads.sabay.com/openx/www/delivery/avw.php?zoneid=" + zone_id + "&amp;cb=" + random_number + "&amp;n=ae6639d1' border='0' alt='' /></a></iframe></div>";

              category_bottom_ads_desktop += ads;
            }
          });
          if (result != "") {
            if (limit == 10) {

              var post_datas = $.parseHTML( result );
              var zone_id = $('#ad_zone_05').attr('zone');
              $('#posts_list_mobile').append(post_datas).append('<div class="ad-cover" style="height:'+height+'px"></div>');
              $('.ad-cover').prev().addClass('ads-prev');
              $('.ad-cover').next().addClass('ads-next');

              loadAds(zone_id, '#posts_list_mobile .category_bottom_ads .ads_items');
              $('.ad-cover').css('height', $(window).height() + 60);
              update_gray_on_read_article();
              update_moment();
              has_campaign();
              window.is_loading = false;
            } else {
              $('#posts_list .list-items').append(result).append(category_bottom_ads_desktop);

              update_moment();
              update_post_view_type();
              window.is_loading = false;
            }
          }
        },
        error: function () {
          window.is_loading = false;
        }
      });
    }
  }
}

var mr1_total   = parseInt($('#mr1_count').val());
var mr2_total   = parseInt($('#mr2_count').val());
var is_load_mr1 = false;
var is_load_mr2 = false;
var mr2_loaded_count = $('.mr2').length > 0 ? $('.mr2').length : 0 ;
var mr1_loaded_count =$('.mr1').length > 0 ? $('.mr1').length : 0 ;

$(document).ready(function () {
  $("a[href^=http]").each(function () {
    var excluded = [
      // format for whitelist: 'google.com', 'apple.com', 'myawesomeblog.com'
      // add your excluded domains here
    ];
    for (i = 0; i < excluded.length; i++) {
      if (this.href.indexOf(excluded[i]) != -1) {
        return true;
      }
    }
    if (this.href.indexOf(location.hostname) == -1) {
      $(this).click(function () { return true; });
      $(this).attr({
        target: "_blank",
        title: "Opens in a new window"
      });
      $(this).click();
    }
  });

  if ($(window).width() <= 767) {
    $('.ad-cover').css('height', $(window).height()+60);
    if ($('#post_content .source-title').length == 2) {
      $('.single-left-content .content-outline #post_content > div:nth-child(2)').attr('style', 'border-bottom: none');
    }

    //hide navigation section on scroll
    lst = 0;
    var is_scrolled = true;

    //set maximum height of navigation
    default_nav_height = $(window).height() - 45;
    $('.navbar-collapse').attr('style', 'max-height: ' + default_nav_height + 'px');

    //scroll mobile menu
    item = $(".menu-collapse ul li");
    menu_width = 0;
    item_length = [];
    wrapper_element = $('.menu-fix .menu-collapse').width();
    menu_wrapper = wrapper_element;
    visible = 1, //Set the number of items that will be visible
    index = 0, //Starting index
    endIndex = (item.length / visible) - 1; //End index

    //get width of menu items
    $(item).each(function (index, value) {
      menu_width += $(this).width();
      item_length.push($(this).width());
    });

    hide_more_button(menu_width, wrapper_element);

    //set width to menu item
    $(".menu-fix ul.menu-items").attr("style", "width: " + menu_width + "px !important");
    stop_clicking = true;
    $(".scroll-menu").on("click", function (e) {
      if (menu_wrapper > menu_width) {
        stop_clicking = false;
      }
      if (stop_clicking) {
        menu_wrapper = menu_wrapper + item_length[index];
        index++;
        item.animate({ 'left': '-=' + (item_length[index] - 5) + 'px' });
      } else {
        $(item).removeAttr('style');
        menu_wrapper = wrapper_element;
        index = 0;
        setTimeout(function () {
          $(item).removeAttr('style');
          stop_clicking = true;
        }, 500);
      }
    });

    // push ad to element
    if (landing_page == 'article') {
      $("#ad-scrolling-wrapper").prepend($("#ad-content").html());
      total_children = $('.post_content .push-ad').children();
      // element_height = $('.header.post_content').height();
      element_height = 0;
      total_ad_spaces = 0;
      content_ad_spacing = 300;
      if ((total_children.length) > 2) {
        new_ad = $('.ads-wrapper').html();
        var space_index = 1;
        var no_ad_article = $("#category_bottom_ads .ads_items").html();
        $(total_children).each(function (index, value) {
          if ( index > 0 ) {
            $(this).addClass('hide-line-spacing').css('position', 'relative');
            element_height += $(this).height();
            tag_name = $(this).prop("tagName");
            /*if (element_height > each_content_height && tag_name != 'HR') {
              $(total_children[index]).addClass('block-above-ad');
              if ($('.mr1').length > 0) {
                height = 270;
              }

              if ($('.mr2').length > 0) {
                height = $(window).height() + 60;
              }

              $(total_children[index]).after('<div class="ad-cover" style="height:'+($(window).height() + 60)+'px"></div>');
              if ( total_ad_spaces >= 1 ) {
                $(".ads-wrapper").append(new_ad);
                $('.ads-wrapper iframe:not(:first-child)').attr('style', 'display: none !important');
                $('.ads-wrapper ins:not(:first-child)').attr('style', 'display: none !important');
              }
              if ($(total_children[index + 1]).prop("tagName") == 'HR') {
                $(total_children[index + 2]).addClass('block-bottom-ad');
                $(total_children[index + 2]).addClass('ad-'+space_index);
                space_index++;
              } else {
                $(total_children[index + 1]).addClass('block-bottom-ad');
                $(total_children[index + 1]).addClass('ad-'+space_index);
                space_index++;
              }
              total_ad_spaces++;
              element_height = 0;
            }*/
          }
          // add first cover
          if (index == 2){
            $(this).addClass('block-above-ad bottom-space');
            $(total_children[index + 1]).addClass('block-bottom-ad');
            $(total_children[index]).after('<div class="ad-cover" style="height:'+($(window).height() + 60)+'px"></div>');
          }

          // check if no ad
          if ( index >= 2 && no_ad_article == "") {
            $(this).addClass('no-ad');
          }
        });
      } else {
        $('.detail.push-ad').attr('style', 'height: auto');
      }
    }

    var loading = $('#display_more_related_posts');
    var scrollTop = 0;
    var element = 0;
    window.is_loading = false;
    $('.article-page .single-left-content .content-outline > div.ad-wrapper').addClass('header-block');
    var each_top_ad = [];
    $('#post_content .detail .block-bottom-ad').each(function(index){
      each_top_ad.push($(this).offset().top);
    });

    var element_index = each_top_ad[0];
    var v = 0;
    var ad_index = 1;

    var isAdsLoadMoreChanged = false;
    var isAdsLoadContentChanged = false;

    //hide menu section on scroll
    lastScrollTop = 0;
    element_height = $('.content-scrolling .list-item.item').height() * 5;
    is_scrolled = true;

    $(window).scroll(function (event) {

      st = $(this).scrollTop();
      if (st > lastScrollTop && $(this).scrollTop() > 110) {
        if (is_scrolled && $('nav').is(':visible')) {
          $('nav').slideUp('slow');
          is_scrolled = false;
        }

        if ( $(this).scrollTop() > 50 ) {
          $('.content-scrolling .category_bottom_ads').show();
          $('.cat-tag-page .category_bottom_ads').css('opacity', 1);
        }

        // homepage first load ad
        // if (landing_page == 'home') {
        //   first_scroll = true;
        //   if ($(this).scrollTop() >= $('#posts_list_mobile .row.list-item.item:nth-child(8)').offset().top && first_scroll) {
        //     var category_bottom_ads = "";
        //     $('#category_bottom_ads').find('.ads_items').each(function (e) {
        //       var zone = $(this);
        //       var zone_id = zone.attr('zone');
        //       if (typeof zone_id !== "undefined") {
        //         var random_number = Math.floor(Math.random() * 99999999999);
        //         var ads = "<iframe id='a5dd2a7c' name='a5dd2a7c' src='https://ads.sabay.com/openx/www/delivery/afr.php?zoneid=" + zone_id + "&amp;cb=" + random_number + "' frameborder='0' scrolling='no' width='100%' height='"+($(window).height() +60)+"'><a href='https://ads.sabay.com/openx/www/delivery/ck.php?n=ae6639d1&amp;cb=" + random_number + "' target='_blank'><img class='img-responsive' src='https://ads.sabay.com/openx/www/delivery/avw.php?zoneid=" + zone_id + "&amp;cb=" + random_number + "&amp;n=ae6639d1' border='0' alt='' /></a></iframe>";

        //         category_bottom_ads += ads;
        //       }
        //     });

        //     $('#posts_list_mobile .category_bottom_ads .ads_items ins').html(category_bottom_ads);
        //     first_scroll = false;
        //   }
        // }
      } else {
        if($('nav').is(':hidden')) {
          $('nav').slideDown('slow');
          is_scrolled = true;
        }

        // homepage scrollTop reload ads
        if ( $(this).scrollTop() < 50 ) {
          $('.content-scrolling .category_bottom_ads').hide();
        }
      }

      // article page
      if (landing_page == 'article') {
        element = $(".content-outline").height() + $(".content-footer").height() - 700;
        scrollTop = $(this).scrollTop();
        paragraphs = $('#post_content .detail').find('p.hide-line-spacing');
        content_height = $('#post_content .detail').height() - ($("#category_bottom_ads").height() + paragraphs.eq( 0 ).height());

        // reload ads in content article
        var lastParagraphOffsetTop = paragraphs.eq(paragraphs.length -1).offset().top;
        var adCoverHeight = $('.ad-cover').offset().top + $('.ad-cover').height();
        var spaceAfterAds = st - adCoverHeight;
        var spaceAfterLastParagraph = lastParagraphOffsetTop - (st + content_height);
        var zone_id = $('#ad_zone_05').attr('zone')
        // for load ads on laod more post
        var beforeRelatedPostHeight = $('.related_post').eq(-2).offset().top;
        var lastRelatedPostHeight = $('.related_post').last().offset().top;
        var spaceBeforeLastRelatedPost = st - beforeRelatedPostHeight;
        var spaceLastRelatedPost = st - lastRelatedPostHeight;
        // scroll down
        if (st > lastScrollTop) {
          // load ads on content
          if ((st > adCoverHeight) && (spaceAfterAds < 15)) {
            if (isAdsLoadContentChanged == false) {
              loadAds(zone_id, '#ad_zone_05');
              isAdsLoadContentChanged = true
            }
          } else {
            if (isAdsLoadContentChanged == true) {
              isAdsLoadContentChanged = false;
            }
          }
          //reload ads on load more post
          if ((spaceBeforeLastRelatedPost > 1 && spaceBeforeLastRelatedPost < 100) || (spaceLastRelatedPost > 1 && spaceLastRelatedPost < 100)) {
            if (isAdsLoadMoreChanged == false) {
              loadAds(zone_id, '.category_bottom_ads_related #ad_zone_05');
              isAdsLoadMoreChanged = true;
            }
          } else {
            if (isAdsLoadMoreChanged == true) {
              isAdsLoadMoreChanged = false;
            }
          }
        } else {
          // scroll up
          if ((st < lastParagraphOffsetTop) && ((spaceAfterLastParagraph > 650) && (spaceAfterLastParagraph < 670))) {
            if (isAdsLoadContentChanged == false) {
              loadAds(zone_id, '#ad_zone_05');
              isAdsLoadContentChanged = true;
            }
          } else {
            if (isAdsLoadContentChanged == true) {
              isAdsLoadContentChanged = false;
            }
          }
        }

        // reload related post ads
        if ( scrollTop > $("#posts_list").height() ) {
          show_more_related_posts(loading, element, scrollTop);
        }

        st1 = scrollTop;
        content_outlet = $('.article-page .content-outline').innerHeight();
        content_footer = $('.article-page .content-footer').innerHeight();
        whole_height = content_outlet + content_footer - 1100;

        if (st1 > lst && scrollTop > 110) {
          if ( scrollTop >= (content_outlet + 300)) {
            $('.related-post-wrapper .category_bottom_ads').css('z-index', 0);
            $('.detail.push-ad .category_bottom_ads').hide();
          }
          var zone_6_height = $('#ad_zone_06').height() || 0;
          if ( scrollTop >= ($('.article-page .content-outline #post_content').height()+zone_6_height+700)) {
            $('.category_bottom_ads.category_bottom_ads_related').show();
          }
        } else {
          // scrollTop to reload ad
          scroll_down = $(this).scrollTop();
          if ( $(this).scrollTop() <= (content_outlet + 200) ) {
            $('.related-post-wrapper .category_bottom_ads').css('z-index', 1);
            $('.detail.push-ad .category_bottom_ads').show();
          }

          if ( scrollTop <= ($('.article-page .content-outline #post_content').height()+500)) {
            $('.category_bottom_ads.category_bottom_ads_related').hide();
          }
          //console.log(scrollTop+' '+$('.article-page .content-outline #post_content').height());

        }
        lst = st1;
      }

      lastScrollTop = st;
    });

    // larger burger menu when toggle
    $(".navbar-toggle").click(function () {
      $(".news-feed-filter a").removeClass('expand-burger-menu-filter');
      chkClass = $(this).hasClass("burger-toggle-menu");
      if (!chkClass) {
        // $('body').attr('style', 'overflow: hidden');
        // $('body').addClass('lock-position');
        $('.menu-fix').slideUp('fast');
        $(this).addClass("burger-toggle-menu");
        $(".news-feed-menu").hide();
      } else {
        setTimeout(function () {
          // $('body').attr('style', 'overflow: visible');
          // $('body').removeClass('lock-position');
          $('.navbar-toggle').removeClass("burger-toggle-menu");
          $('.menu-fix').slideDown('fast');

        }, 300);
      }
    });


    //hide dropdown menu when user scroll
    $(window).scroll(function () {
      var lockBodyPosition = $('.navbar-ex1-collapse').hasClass('in');
      if (lockBodyPosition) {
        $('.navbar-toggle').click();
      }
    });

    // show/hide facebook comment
    $(".comment-block").click(function () {
      $('.facebook-comments').toggle();
    });

    // hide/show next menu item button on screen rotation
    $(window).on("orientationchange", function () {
      wrapper_element = $('.menu-fix .menu-collapse').width();
      win_height = $(window).width() - 45;
      if (window.innerHeight > window.innerWidth) {
        hide_more_button(wrapper_element, menu_width);
        $('.navbar-collapse').attr('style', 'max-height: ' + win_height + 'px');
      }
      if (window.innerWidth > window.innerHeight) {
        hide_more_button(wrapper_element, menu_width);
        $('.navbar-collapse').attr('style', 'max-height: ' + win_height + 'px');
      }
    });

    function hide_more_button(wrapper_element, menu_width) {
      // hide button more if menu item shorter
      if ((menu_width + 20) < wrapper_element) {
        $('.more-menu-item').show();
      } else {
        $('.more-menu-item').hide();
      }
    }

    $(document).ready(function () {
      // check if block ad
      ad_block_article = $("#category_bottom_ads .ads_items").html();
      ad_status = $(".article-page .mobile-display.ads-wrapper").attr('ad-status');
      if (ad_block_article == "") {
        $(".article-page .mobile-display.ads-wrapper").attr('style', 'display:none');
        $(".article-page .single-left-content").removeClass('scrolling-ads');
        $(".article-page .single-left-content .content-outline .header.post_content").attr('style', 'margin-top:0').addClass('no-ad');
        $(".article-page #post_content .detail").removeClass('push-ad');
        $(".article-page .single-left-content .content-outline").attr('style', 'padding:0');
        $(".article-page .single-left-content .content-outline").addClass('no-padding');
        $(".article-page .single-left-content .content-outline .row, .article-page .single-left-content .content-footer, .article-page .single-left-content .content-outline .source-title").addClass('no-ad');
        $(".article-page .ad-cover").css('display', 'none');
      }

      if ( ad_status != "") {
        $(".article-page .header.post_content").removeClass('header-block');
        $(".article-page .content-outline").removeAttr('style');
        $(".article-page #post_content .ad-wrapper").removeClass('ad-sm-scr');
      }

      // if blocked ad
      setTimeout(function(){
        // if has content slide
        if ($('.article-page #post_content .detail').find('#content_slider').length > 0) {
          $('.article-page #post_content .detail #content_slider').next().addClass('padding-space');
        }
      }, 2000);
    });
  } else {
    $(document).ready(function () {
      // check if block ad
      $(".article-page").removeAttr('id');
      $(".article-page .mobile-display.ads-wrapper").attr('style', 'display:none');
      $(".article-page .single-left-content").removeClass('scrolling-ads');
      $(".article-page #post_content .detail").removeClass('push-ad');
    });
  }
});

//load more related posts
var content_article = $(".content-outline").height() + 750;
var article_block = $('.related-post-wrapper .related_post').height();
var ads_cover_height = $('.related-post-wrapper .ad-cover').height();
var total_height = content_article + article_block + ads_cover_height;
var total_heights = total_height;

function show_more_related_posts(loading, element, scrollTop){
  var default_ads = this.options.default_ads;
  var type = $('#type').val();
  var windowHeight = $(window).height();
  var parent = $('.related-post-wrapper');
  var bottomOfParent = parent.offset().top + parent.outerHeight(true);

  // start load content when scroll to top lasted posts element + 2 screen height
  var startLoadTop = ((bottomOfParent - windowHeight) - (windowHeight * 2));
  var ad_cover_height = 270;

  content_height = element;
  if(typeof loading.offset() != "undefined") {
    if((scrollTop + 90) >= startLoadTop && !window.is_loading){
      display_ads_config();
      window.is_loading = true;
      load_ad = false;
      window.current_page += 1;
      var page = window.current_page + 1;
      var rel = loading.attr('rel');
      var url = DOMAIN + rel + "/" + window.current_page;
      loading.find('i').show();
      var width  = 728;
      var height = 90;
      reload_ad = false;

      $.ajax({
        url: url,
        type: 'GET',
        data: {'type' : type},
        success: function(contents){
          loading.find('i').hide();
          var mobile = ($( window ).width() <= 768) ? true:false;
          if(contents != "") {
            $('.related-post-wrapper .mobile-view')
              .append(contents);
            $('.ad-cover').css('height', $(window).height()+60);
            // $('.related-post-wrapper .ad-cover').css('height', height);

            update_moment();
            window.is_loading = false;
            content_height += $(".content-footer").height();
          }
        },
        error: function() {
          window.is_loading = false;
        }
      });
    }
  }
}

function replace_ad_on_mobile(ad_ele, ad_html, element_offset, ad_cover_height){
  // first call this function ads is not yet loaded
  var loaded_ads = false;
  // windows on scroll
  $(window).scroll(function () {
    // check if ads not yet loaded
    if (!loaded_ads) {
      // when windows scroll to fist post's top position
      if ($(this).scrollTop() >= $(element_offset).offset().top) {
        // load ads
        ad_ele.html(ad_html);
        loaded_ads = true;
        // $('#posts_list_mobile .ad-cover').css('height', ad_cover_height);
      }
    }
  });
}

function has_campaign () {
  $(document).ready(function () {
    campaign = $("ul.nav-tabs.home-tabs li:nth-child(2) a div");
    campaign_article = $(".header.post_content div.post-top-bar");
    if ( campaign.length > 0 ) {
      $(".tab-content [role='tabpanel']").addClass('has-campaign');
    } else if ( campaign_article.length > 0 ) {
      $("#post_content .content-grp-img").addClass('no-padding');
      $(".article-page .content-outline").addClass('has-campaign');
      $(".content-outline").attr('style', 'padding-top:20px !important');
      $(".ad-wrapper").addClass('ad-sm-scr');
    }
  });
}

function display_ads_config() {
  if (mr2_total > mr1_total) {
    if (mr2_loaded_count < mr2_total) {
      is_load_mr2 = true;
      is_load_mr1 = false;is_load_mr2
      mr2_loaded_count = mr2_loaded_count + 1;
    } else {
      is_load_mr2 = false;
      is_load_mr1 = true;
      mr1_loaded_count = 1;
      mr2_loaded_count = 0;
    }
  } else {
    if (mr1_loaded_count < mr1_total) {
      is_load_mr1 = true;
      is_load_mr2 = false;
      mr1_loaded_count = mr1_loaded_count + 1;
    } else {
      is_load_mr1 = false;
      is_load_mr2 = true;
      mr2_loaded_count = 1;
      mr1_loaded_count = 0;
    }
  }

  if (mr1_total == 0 && mr2_total == 0) {
    is_load_mr1 = false;
    is_load_mr2 = false;
  } else {
    if (mr1_total == 0) {
      is_load_mr1 = false;
      is_load_mr2 = true;
    }

    if (mr2_total == 0) {
      is_load_mr2 = false;
      is_load_mr1 = true;
    }
  }
}

function loadAds(zoneid, showDiv) {
  $.ajax({
    url: 'https://ads.sabay.com/openx/www/delivery/afr.php?zoneid='+ zoneid +'&cb=54559730676',
    type: 'GET',
    success: function(contents){
      // debugger;
      var cutFromIndex = contents.indexOf('<body>')+6;
      var cutToIndex = contents.indexOf('</body>');
      var cutContents = contents.substr(cutFromIndex, cutToIndex);
      $(showDiv).html(cutContents);
    },
    error: function() {
      window.is_loading = false;
    }
  });
}
