SBAdvertisement = function(options) {
  this.init(options);
}

$.extend(SBAdvertisement.prototype, {

  default: {
    app_code: null,
    default_ads: null,
    gateway_banners: null,
    menu_ads: null,
    tag_ads: null,
    is_landing_page: false,
    is_menu_page: false,
    is_tag_page: false,
    is_article_page: false,
    current_category: null,
    current_tag: null,
    current_tags: [],
    current_post: null
  },

  // object variables
  options: new Object(),

  init: function(options) {
   // do initialization here
   this.options = this.merge(this.default, options);
  },

  merge: function(obj, src) {
      for (var key in src) {
          if (src.hasOwnProperty(key)) obj[key] = src[key];
      }
      return obj;
  },

  consoleOptions: function() {
   console.log(this.options);
  },

  is_expired: function(date) {
    var now = new moment().valueOf();
    var date = new moment.utc(date).valueOf();
    return (now >= date);
  },

  replace_sponsor_ads: function(ads) {
    var default_ads = this.options.default_ads;
    if (default_ads.length < 3) {
      return;
    }

    var campaign_date = ads[0].campaign.end_date;

    if (this.is_expired(campaign_date)) {
      return;
    }

    switch(ads[0].campaign.option) {
      case "half":
        default_ads[2] = ads[0];
        break;

      case "full":
        default_ads[1] = ads[0];
        default_ads[2] = ads[0];
        break;

      case "special":
        default_ads[0] = ads[0];
        default_ads[1] = ads[1];
        default_ads[2] = ads[1];

        this.replace_special_gateway_banners();
        break;
      case "inhouse":
        default_ads[0] = ads[0];
        default_ads[1] = ads[1];
        default_ads[2] = ads[2];
        default_ads[3] = ads[3];
        if (ads[4]) {
          default_ads[4] = ads[4];
        }
        break;
    }

  },

  replace_special_gateway_banners: function()
  {
    var gateway_banners = this.options.gateway_banners;

    $.each(gateway_banners, function(index, banner) {
      if(typeof banner !== "undefined" && banner.campaign.option != "special") {
        gateway_banners.splice(index, 1);
      }
    });
  },

  handle_menu_page: function()
  {
    var category = this.options.current_category;
    if (!category) {
      return;
    }

    this.handle_menu_sponsor(category);
    this.handle_exclude_menu();
  },


  handle_menu_sponsor: function(menu)
  {
    var menu_id = menu.id;
    var menu_ads = this.options.menu_ads;

    if(typeof menu_ads[menu_id] === 'undefined') {
        return false;
    }
    menu_ad = menu_ads[menu_id];

    this.replace_sponsor_ads(menu_ad);
    return true;
  },

  handle_tag_page: function()
  {
    var tag = this.options.current_tag;
    if (!tag) {
      return;
    }
    this.options.current_tags[0] = tag;
    this.handle_tag_sponsor(tag);
    this.handle_exclude_tag();
  },

  handle_tag_sponsor: function(tag)
  {
    var tag_ads = this.options.tag_ads;
    if(typeof tag_ads[tag.id] === 'undefined') {
        return false;
    }
    tag_ad = tag_ads[tag.id];

    this.replace_sponsor_ads(tag_ad);
    return true;
  },


  handle_article_page: function()
  {
    var post = this.options.current_post;
    if (!post) {
      return;
    }

    var tags = post.tags;
    var found_ad = false;
    var that = this;

    this.handle_exclude_content();
    this.handle_exclude_tags();

    if(tags.length > 0) {
      this.options.current_tags = tags;
      $.each(tags, function(index, tag) {
        found_ad = that.handle_tag_sponsor(tag);
        if(found_ad) {
          // Stop loop
          return false;
        }
      });
    }

    this.handle_exclude_content();
    this.handle_exclude_tags();

    if(found_ad) {
      return;
    }

    var app_code = this.options.app_code;

    var menu = post.menus[app_code];

    this.options.current_category = menu;
    this.handle_menu_sponsor(menu);
    this.handle_exclude_menu();
  },

  ad_zone_handler: function() {

    if(this.options.is_menu_page) {

      this.handle_menu_page();

    } else if(this.options.is_tag_page) {

      this.handle_tag_page();

    } else if(this.options.is_article_page) {

      this.handle_article_page();

    }

  },

   display: function() {

    this.ad_zone_handler();
    for (i = 0; i < this.options.default_ads.length; i++) {
      var id = i + 1;
      if(id < 10) {id = "0"+id}
      this.create_ads("ad_zone_"+id, this.options.default_ads[i].zone);
    }


    for (i = 0; i < this.options.gateway_banners.length; i++) {
      var id = i + 1;
      if(id < 10) {id = "0"+id}
      this.create_ads("gateway_banner_"+id, this.options.gateway_banners[i].campaign.gateway_banner);
    }

   },

   create_ads: function(ele_id, ads) {

    var element = $('body').find('#'+ele_id+':visible');

    if (typeof ads != 'undefined' && ads ) {
      element.attr('zone', ads).html( this.template(ads) );
    }

   },

   template: function(ad_zone) {
    return '<ins data-revive-zoneid="'+ad_zone+'"  data-revive-id="4b9ca95baee4430ad25d6d07da6464a5"></ins><script async src="//ads.sabay.com/openx/www/delivery/asyncjs.php">';
   },

   handle_exclude_menu: function() {
    var that = this;
    var gateway_banners = [];
    if (that.options.gateway_banners.length > 0) {
      $.each(that.options.gateway_banners, function( index, gateway_banner ) {
        if (typeof gateway_banner != 'undefined') {

          var exclude_menu_ids = gateway_banner.campaign.param.exclude_menu_ids;
          var current_menu_id = that.options.current_category.id;
          if (!exclude_menu_ids.includes(current_menu_id.toString())) {
            gateway_banners.push(gateway_banner);
          }
        }
      });
    }

    this.options.gateway_banners = gateway_banners;

   },

   handle_exclude_tag: function() {
    var that = this;
    var gateway_banners = [];

    if (that.options.gateway_banners.length > 0) {
      for (var i = 0; i < that.options.gateway_banners.length; i++) {
        var gateway_banner = that.options.gateway_banners[i];
        var current_tag_id = that.options.current_tags[0].id;
        if (typeof gateway_banner != 'undefined') {
          var exclude_tag_ids = gateway_banner.campaign.param.exclude_tag_ids;
          if (!exclude_tag_ids.includes(current_tag_id.toString())) {
            gateway_banners.push(gateway_banner);
          }
        }
      }
    }

    this.options.gateway_banners = gateway_banners;

   },

   handle_exclude_tags: function() {
    var is_sponsor = false;
    var that = this;
    var gateway_banners = [];
    var tag_ids = [];
    if (that.options.gateway_banners.length > 0) {
      for (var i = 0; i < that.options.gateway_banners.length; i++) {
        var gateway_banner = that.options.gateway_banners[i];
        if (typeof gateway_banner != 'undefined') {
          var exclude_tag_ids = gateway_banner.campaign.param.exclude_tag_ids;
          for (var j = 0; j < that.options.current_tags.length; j++) {
            var tag = that.options.current_tags[j];
            var found_ads = that.handle_tag_sponsor(tag);
            if (found_ads) {
              is_sponsor = true;
              if (!exclude_tag_ids.includes(tag.id.toString())) {
                gateway_banners.push(gateway_banner);
              }
            }
          }
        }
      }
    }
    if(is_sponsor)
      this.options.gateway_banners = gateway_banners;
    else
      this.options.gateway_banners = this.options.gateway_banners;

   },

   handle_exclude_content: function() {
    var that = this;

    $.each(that.options.gateway_banners, function( index, gateway_banner ) {

      if (typeof gateway_banner != 'undefined') {
        var exclude_content_ids = gateway_banner.campaign.param.exclude_content_ids;
        var current_content_id = that.options.current_post.id;

        if (exclude_content_ids.includes(current_content_id.toString())) {
          that.options.gateway_banners.splice(index, 1);
        }
      }

    });

    this.options.gateway_banners = that.options.gateway_banners;

   }


});

$('#ads-close').click(function(){
  $(this).parent().remove();
  return false;
});